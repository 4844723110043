import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Music Streaming App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/music-streaming-app/"
    metaDescription="Designing the next streaming service or the next platform for content creators has never been easier with our music streaming app template."
    description="
    h2:Introducing AudioX: A music streaming app design template for your own design project
    <br/>
    AudioX is a gorgeous music streaming mobile app template for anyone designing a modern streaming media service. Whether you are building a new podcast app, a new music streaming app, or a video streaming service, AudioX has everything you could possibly need for <a:https://uizard.io/prototyping/>UI prototyping</a> your own design.
    <br/>
    h3:A music streaming app template for mobile designs that emulates the greats
    <br/>
    It's estimated that Spotify has over 400 million monthly active users, YouTube Music over 2 billion users, SoundCloud over 175 million users, and Apple Music over 60 million subscribers.
    You just need to look at these figures to realize how these apps have truly transformed the way we consume music, listen to podcasts, and discover independent artists. 
    <br/>
    If you are interested in building your own media streaming service, you definitely want to borrow the winning design principles used by all these popular services in your own app. This template is designed to do just that!
    <br/>
    h3:Create your own music streaming app design rapidly and collaboratively
    <br/>
    All the design components, screens, and sections in AudioX were created after analyzing the most popular music streaming apps, and now they're yours to take away and use to customize to your own idea alone or with your team. Uizard <a:https://uizard.io/templates/mobile-app-templates/>mobile app design templates</a> come with all the must-have design and UI considerations baked in, making your design flow quicker and easier than ever before.
    "
    pages={[
      "Screens of the login flow (sign-up, sign-in, user registration)",
      "Home screen",
      "Content search screen",
      "Personal library screen",
      "Screen for showcasing the profile of artists / content creators",
      "Screen for listing all the work of a specific artist / content creator",
      "Content streaming screen",
      "User profile screen",
    ]}
    projectCode="oaVXAZewBqhGpyX7RVV5"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Music Streaming Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Music Streaming Mobile App: home screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Music Streaming Mobile App: search screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Music Streaming Mobile App: music library"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Music Streaming Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/music-streaming-app/Music-App-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/music-streaming-app/Music-App-Home.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/music-streaming-app/Music-App-Search.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/music-streaming-app/Music-App-Library.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/music-streaming-app/Music-App-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
